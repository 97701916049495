import React from "react";
import { Nav, Navbar, Container } from "react-bootstrap";
import Login from "../login/Login";

export const NavBar = () => {
  return (
    <Navbar collapseOnSelect expand="lg"  variant="dark"  style={{ backgroundColor: "#000000" }}>
      <Container>
        <Navbar.Brand href="#home">
          <img
            alt=""
            src="https://jaskoworks.com/assets/jwfit/jaskoworks_logo.png"
            width="200"
            height="20"
            className="d-inline-block align-top"
          />{" "}
          
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="#features">OUR MISSION</Nav.Link>
            <Nav.Link href="#pricing">PRICING</Nav.Link>
            {/* <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown> */}
          </Nav>
          <Nav>
            <Login />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
