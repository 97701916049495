import React from "react";
import { Carousel } from "react-bootstrap";

export const Header = () => {
  return (
    <Carousel className="Header vh-10" interval={null}>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="https://jaskoworks.com/assets/jwfit/header_banner.jpeg"
          alt="First slide"
        />
        <Carousel.Caption>
          <h3>Try to be better than yourself...</h3>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="https://jaskoworks.com/assets/jwfit/header_banner.jpeg"
          alt="Second slide"
        />
        <Carousel.Caption>
        <h3>Try to be better than yourself...</h3>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="https://jaskoworks.com/assets/jwfit/header_banner.jpeg"
          alt="Third slide"
        />
        <Carousel.Caption>
        <h3>Try to be better than yourself...</h3>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};
