import React from "react";
import { Row, Col, Card, Container } from "react-bootstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode, faChartLine, faHandsHelping } from '@fortawesome/free-solid-svg-icons';

export const SectionOne = () => {
  return (
    <Row>
      <Col className="section-one text-center">
        <Container>
          <Row className="section-one-cards">
            <Col>
              <Card style={{ width: "18rem" }}>
                <Card.Body>
                <FontAwesomeIcon icon={faCode} className="card-icon"/>
                  <Card.Title>Bespoke websites</Card.Title>
                  <Card.Text>
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card style={{ width: "18rem" }}>
                {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                <Card.Body>
                <FontAwesomeIcon icon={faChartLine} className="card-icon"/>
                  <Card.Title>Promotion</Card.Title>
                  <Card.Text>
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </Card.Text>
                  {/* <Button variant="primary">Go somewhere</Button> */}
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card style={{ width: "18rem" }}>
                <Card.Body>
                <FontAwesomeIcon icon={faHandsHelping} className="card-icon"/>
                  <Card.Title>Support</Card.Title>
                  <Card.Text>
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Col>
    </Row>
  );
};
