import React from "react";
import { NavBar } from "../navbar/Navbar";
import { Header } from "../header/Header";
import { SectionOne } from "../sectionOne/SectionOne";
import { Footer } from "../footer/Footer";

import { Row, Col } from "react-bootstrap";

export const Main = () => (
  <Row>
    <Col>
      <NavBar></NavBar>
      <Header></Header>
      <SectionOne></SectionOne>
      <Footer></Footer>
    </Col>
  </Row>
);
