import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Main } from './components/main/Main';

function App() {
  return <Main />;
}

export default App;
