import React from "react";
import { Row, Col } from "react-bootstrap";

export const Footer = () => {
  return (
    <Row>
      <Col>
        <footer>
          <div className="container-fluid d-flex justify-content-center align-items-center">
            <div className="text">Copyright Roman Jasko 2021</div>
          </div>
        </footer>
      </Col>
    </Row>
  );
};
